<template>
  <defaultFieldTypes
    :fieldAttributes="{
      ...fieldAttributes,
      associatedOption: associatedOption,
    }"
    :field="field"
    :templateContent="result"
    v-on="$listeners"
  :value="value"></defaultFieldTypes>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["fieldAttributes", "field", "result","value"],
  data() {
    return { associatedOption: this.fieldAttributes.associatedOption };
  },
  watch: {
    "result.valueLine.n_subscriberId": function (subscriberID) {
      this.getIMSIArray(subscriberID);
    },
  },
  methods: {
    getIMSIArray(subscriberID) {
      let caller = this;
      var options = {
        function: "getIMSIArray",
        requestType: "ajax",
        subscriberID: subscriberID,
      };
      this.frameworkAxiosRequest({
        method: 'post',
        url:
            typeof this.result != 'undefined' &&
            typeof this.result.tableUrl != 'undefined'
                ? this.result.tableUrl
                : '',
        data: options,
      })
          .then(function (response) {
          caller.associatedOption = response.data.result.json.imsiArray;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
  },
};
</script>